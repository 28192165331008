.loader {
  font-size: 50px;
  color: white;
  margin-left: 10%;
  margin-top: 25%;
  text-shadow: 2px 2px 2px rgb(150, 111, 74);
  animation: pulsate 1.5s linear infinite;
  -webkit-animation: pulsate 1.5s linear infinite;
}

@keyframes pulsate {
  25% {
    transform: scale(110%);
  }
  50% {
    transform: scale(120%);
  }
  75% {
    transform: scale(110%);
  }
}

.loader-container {
  justify-self: center;
  height: 100vh;
}
