.claim-container {
  max-width: 1200px;
  margin: 5% 0;
  transition: 250ms ease-in-out;
}

.claim-container:hover {
  transform: scale(1.1);
}

.info-header {
  grid-column: 1;
  display: block;
  grid-template-columns: 3fr 1fr 1fr 2fr 5fr;
}
