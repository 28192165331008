.empty {
  font-size: 50px;
  color: white;
  margin-left: 10%;
  margin-top: 25%;
  text-shadow: 2px 2px 2px rgb(150, 111, 74);
}

.empty-container {
  justify-self: center;
  height: 100vh;
}
