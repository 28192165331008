.signout-button {
  background-color: rgb(68, 68, 68);
  color: white;
  height: 5vh;
  width: 21vw;
  min-width: 75px;
  max-width: 150px;
  min-height: 4vh;
  border-radius: 5px;
  box-shadow: -2px 2px 6px rgb(230, 255, 222);
}
.signout-button:hover {
  background-color: rgb(255, 242, 242);
  color: black;
  box-shadow: -2px -2px 5px rgb(230, 255, 222);
}

.user-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  color: white;
}
