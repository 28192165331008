.photo-container {
  display: block;
}

.thumbnail {
  background-size: cover;
  background-repeat: no-repeat;
  height: 125px;
  width: 125px;
  cursor: zoom-in;
}

.full-view {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: zoom-out;
  width: 95vw;
  height: 50vh;
}

.breaker {
  page-break-before: always;
  page-break-inside: avoid;
  page-break-after: always;
}
