.edit-notes-container {
  height: 1000px;
  background-color: rgb(24, 32, 34);
}

.note-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  max-width: 600px;
  margin: 10px;
}

.new-note-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
}
