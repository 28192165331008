.edit-meta-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.edit-meta-form input[type="text"],
.edit-meta-form textarea,
.edit-meta-form select {
  width: 80%;
  padding: 10px;
  margin-bottom: 1vh;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

.edit-meta-form input[type="text"]:focus,
.edit-meta-form textarea:focus {
  border-color: #064670;
  outline: none;
  box-shadow: 0px 0px 8px rgba(255, 132, 50, 0.5);
}

.edit-meta-button {
  width: 13vw;
  height: 8vh;
  border-radius: 5px;
  min-width: 100px;
}
.edit-meta-button:hover {
  width: 13vw;
  height: 8vh;
  min-width: 100px;
  background-color: black;
  color: white;
}
