.main-container {
  background-color: rgb(24, 32, 34);
}

.menu-container {
  display: flex;
  justify-content: flex-start;
  gap: 2px;
  height: 8vh;
}
