.witnesses-container {
  display: block;
}

.witness-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin: 1em;
}

.witness-info-container {
  grid-column: 1;
}

.code-button-container {
  grid-column: 2;
  display: flex;
  justify-content: right;
}

.code-button {
  height: 3em;
  width: 3em;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgb(49, 49, 49);
  color: white;
}
