.safety-pay-info-container {
  grid-column: 3;
  max-width: 100px;
  text-align: center;
  justify-self: right;
  padding: 1%;
  border: 1px solid black;
}

.pci-image {
  height: 100px;
  width: 100px;
  grid-column: 1;
  background-image: url("~/public/PCIImage.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
