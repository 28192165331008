.primary-report-container {
  display: block;
  max-width: 800px;
  margin: 2%;
  padding: 2%;
  background-color: rgb(255, 251, 245);
  border: 2px solid black;
  border-radius: 5px;
}

.primary-report-container h5,
h4 {
  border-bottom: 1px solid black;
}

.info-section {
  display: flex;
}
