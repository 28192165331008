.home-container {
  min-height: 100vh;
  background-image: url("~/public/PCIImage.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  text-align: center;
}

.home-container h1 {
  position: absolute;
  top: 25%;
  left: 10%;
  color: whitesmoke;
  font-size: 75px;
  text-shadow: 5px 5px 5px rgb(253, 119, 1);
}
