.dashboard-container {
  display: flex;
  justify-content: center;
  background-color: rgb(24, 32, 34);
  min-height: 1000px;
}

.options-container {
  display: flex;
  justify-content: left;
}

.selector-container {
  margin-left: 0.5vh;
}

.selector {
  margin-left: 1vw;
  margin-right: 1vw;
  min-width: 100px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.claims-container {
  display: block;
  width: 85%;
  padding-top: 3vh;
}
