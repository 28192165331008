.details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(24, 32, 34);
  min-height: 100vh;
  margin-bottom: 5%;
  z-index: -1;
}

.claim-header {
  padding-left: 10%;
}

.info-section {
  display: grid;
}

.menu-button {
  width: 65px;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 2px;
}

#modal-input {
  opacity: 1 !important;
  height: "50px";
  font-size: "24px";
  border: "2px solid darkRed";
  border-radius: "5px";
  z-index: 1;
}

.shake {
  animation: shake 50ms linear infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
