.ppe-container {
  display: block;
}

.ppe {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin: 1em;
}
